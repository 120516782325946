var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "650px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.layerClose()
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.ONEX010P050.001"))),
          ]),
          _c("div", { staticClass: "content_box mt10" }, [
            _c("div", { staticClass: "bkbl_no text_right" }, [
              _c("span", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("msg.ONEX010P050.002"))),
              ]),
              _c("span", { staticClass: "txt_bar2" }),
              _c("span", [_vm._v(_vm._s(_vm.parentInfo.blNo))]),
            ]),
            _c("div", {
              staticStyle: { width: "100%", height: "auto" },
              attrs: { id: "realgrid-bl-payment-accountless-pop" },
            }),
          ]),
          _c("div", { staticClass: "mt10 text_center" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.goCalcIssueMain()
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("msg.ONEX010P050.006")))]
            ),
            _vm.isShowInvoiceBtn
              ? _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.combineInvoicePopCall()
                      },
                    },
                  },
                  [_vm._v(_vm._s(_vm.$t("msg.ONEX010P050.007")))]
                )
              : _vm._e(),
          ]),
          _c(
            "win-layer-pop",
            {
              staticClass: "invoice_pdf_popup",
              staticStyle: { "z-index": "99999" },
            },
            [
              _vm.popupComponent
                ? _c(_vm.popupComponent, {
                    tag: "component",
                    attrs: { "parent-info": _vm.popupParams },
                    on: { close: _vm.closePopup },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }