<template>
  <div class="popup_wrap" style="width:650px;">
    <button
      class="layer_close"
      @click="layerClose()"
    >
      close
    </button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('msg.ONEX010P050.001') }}</h1><!-- 미정산 내역 -->
      <div class="content_box mt10">
        <div class="bkbl_no text_right">
          <span class="title">{{ $t('msg.ONEX010P050.002') }}</span><!-- B/L No. -->
          <span class="txt_bar2"></span>
          <span>{{ parentInfo.blNo }}</span>
        </div>
        <div
          id="realgrid-bl-payment-accountless-pop"
          style="width:100%;height:auto;"
        />
      </div>
      <div class="mt10 text_center">
        <a
          class="button blue lg"
          href="#"
          @click.prevent="goCalcIssueMain()"
        >{{ $t('msg.ONEX010P050.006') }}</a><!-- 정산하기 -->
        <a v-if="isShowInvoiceBtn"
           class="button blue lg"
           href="#"
           @click.prevent="combineInvoicePopCall()"
        >{{ $t('msg.ONEX010P050.007') }}</a><!-- Invoice -->
      </div>
      <win-layer-pop class="invoice_pdf_popup" style="z-index: 99999;">
        <component
          v-if="popupComponent"
          :is="popupComponent"
          @close="closePopup"
          :parent-info="popupParams"
        />
      </win-layer-pop>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import CalcIssue from '@/api/rest/settle/calcIssue'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'frtCdNm', dataType: 'text' },
  { fieldName: 'totalAmt', dataType: 'text' },
  { fieldName: 'locAmtDesc', dataType: 'text' }
]

export const columns = [
  {
    name: 'frtCdNm',
    fieldName: 'frtCdNm',
    type: 'data',
    width: '50',
    styleName: 'text_left',
    header: {
        text: '운임 항목'
    }
  },
  {
    name: 'totalAmt',
    fieldName: 'totalAmt',
    type: 'data',
    width: '25',
    styleName: 'text_right',
    header: {
        text: '전체 금액'
    }
  },
  {
    name: 'locAmtDesc',
    fieldName: 'locAmtDesc',
    type: 'data',
    width: '25',
    styleName: 'text_right',
    header: {
        text: '미정산 금액'
    }
  }
]

export default {
  name: 'BLPaymentAccountlessPop',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    CombineInvoicePop: () => import('@/pages/pdf/popup/CombineInvoicePop') // combine Invoice
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          eiCatCd: '',
          list: []
        }
      }
    }
  },
  data () {
    return {
      popupComponent: null,
      popupParams: {},
      isShowInvoiceBtn: false, // 인보이스버튼 보여줄지 여부
      auxInvoiceData: []
    }
  },
  created () {
    this._ = require('lodash')

    // invoice 버튼을 인도는 보여주지 않음
    if (vmApp.memberDetail.userCtrCd !== 'IN') {
      isShowInvoiceBtn = true
    }
  },
  mounted () {
    //리얼그리드 셋팅
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid-bl-payment-accountless-pop')
    gridView.setDataSource(provider)
    provider.setFields(fields)
    gridView.setColumns(columns)

    this.setMessageProperty()
    gridView.displayOptions.fitStyle = 'fill'
    gridView.sortingOptions.enabled = false

    // [s] 그리드 크기 자동조절을 위한 설정
    gridView.header.height = 25
    gridView.displayOptions.rowHeight = 25
    gridView.displayOptions.vscrollBar = false
    gridView.footers.visible = false
    // [e] 그리드 크기 자동조절을 위한 설정

    gridView.setEditOptions({
      editable: false,
      updatable: false
    })

    //인디케이터 없애기
    gridView.setRowIndicator({
      visible: false
    })
    //상태바 없애기
    gridView.setStateBar({
      visible: false
    })
    //체크바 없애기
    gridView.setCheckBar({
      visible: false
    })

    const $vm = this
    //API : B/L Payment 미정산 내역 가져오기
    $vm.getBlPaymentAccountlessList()
  },
  methods: {
    layerClose: function () {
      this.$emit('close')
      return false
    },
    async getBlPaymentAccountlessList () {
      const list = this.$props.parentInfo.list
      let cloneList = this._.cloneDeep(list)
      if (list.length > 0) {
        if (this.$props.parentInfo.eiCatCd === 'I') {
          cloneList = cloneList.filter(e => e.frtPncCd === 'C')
        }

        // provider.setRows(list)
        provider.setRows(cloneList)
        const headerHeight = gridView.header.height // 헤더 높이
        const rowHeight = gridView.displayOptions.rowHeight // 행 높이
        const rowCount = gridView.getItemCount() // 행 갯수
        const totalHeight = headerHeight + (rowHeight * rowCount)
        $('#realgrid-bl-payment-accountless-pop').height(totalHeight)
        gridView.resetSize()
      }
    },
    //다국어 적용
    setMessageProperty () {
      const header1 = gridView.getColumnProperty('frtCdNm', 'header')
      const header2 = gridView.getColumnProperty('totalAmt', 'header')
      const header3 = gridView.getColumnProperty('locAmtDesc', 'header')

      header1.text = this.$t('msg.ONEX010P050.003') // 운임 항목
      header2.text = this.$t('msg.ONEX010P050.004') // 전체 금액
      header3.text = this.$t('msg.ONEX010P050.005') // 미정산 금액

      gridView.setColumnProperty('frtCdNm', 'header', header1)
      gridView.setColumnProperty('totalAmt', 'header', header2)
      gridView.setColumnProperty('locAmtDesc', 'header', header3)
    },
    // 정산 발행 화면으로 이동
    goCalcIssueMain () {
      const searchParams = {
        eiCatCd: this.parentInfo.eiCatCd,
        tab: '1',
        dtKnd: 'BL',
        asProfileYn: 'N',
        keyword: this.parentInfo.blNo
      }
      this.$router.push({ name: 'calcIssueMain', params: searchParams }).catch(() => {})
    },
    async combineInvoicePopCall () {
      this.auxInvoiceData = []

      const params = {
        eiCatCd: this.parentInfo.eiCatCd,
        items: [
          { blNo: this.parentInfo.blNo, remark: '' }
        ]
      }

      if (this.parentInfo.podCtrCd === 'ID' || this.parentInfo.polCtrCd === 'ID') {
        this.auxInvoiceData.push({ blNo: blNo })
      }

      await this.recalculateFreightBillInfo(params.items)

      await this.auxInvoiceCreate()

      this.openPopup('CombineInvoicePop', params)
    },
    openPopup (compNm, params) {
      this.popupParams = params
      this.popupComponent = compNm
      this.$ekmtcCommon.layerOpen('.invoice_pdf_popup')
    },
    closePopup () {
      this.popupParams = {}
      this.popupComponent = null
      this.$ekmtcCommon.layerClose('.invoice_pdf_popup')
    },
    async recalculateFreightBillInfo (items) {
      let blArrStr = ''

      for (let i = 0; i < items.length; i++) {
        if (blArrStr !== '') {
          blArrStr += ','
        }

        blArrStr += items[i].blNo
      }

      let req = {
        blNo: blArrStr,
        eiCatCd: this.parentInfo.eiCatCd
      }

      await CalcIssue.recalculateFreightBillInfo(req).then(response => {
        console.log('Success')
      }).catch(e => {
        console.log(e)
      })
    },
    async auxInvoiceCreate () {
      let blArrStr = ''

      if (this.auxInvoiceData.length > 0) {
        for (let i = 0; i < this.auxInvoiceData.length; i++) {
          if (blArrStr !== '') {
            blArrStr += ','
          }

          blArrStr += this.auxInvoiceData[i].blNo
        }

        let req = {
          blNo: blArrStr
        }

        await CalcIssue.auxInvoiceCreate(req).then(response => {
          console.log('Success')
        }).catch(e => {
          console.log(e)
        })
      }
    }
  }
}
</script>
